import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Home from "./home";
import Welcome from "./welcome";

import Axios from "axios";

Vue.use(VueRouter);

const routes = [].concat(Home).concat(Welcome);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let auth = store.getters.isAuth;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth) {
      next();
    } else {
      next({
        path: "/login",
        replace: true,
      });
    }
  } else {
    next();
  }
});

export default router;
