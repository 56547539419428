const HomeView = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/HomeView.vue");

const RegisterView = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/RegisterView.vue");

const LoginView = () =>
  /* webpackChunkName: "view-[request]" */ import("@/views/LoginView.vue");

export default [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },

  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];
