import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import { StreamBarcodeReader } from "vue-barcode-reader";

Vue.use(StreamBarcodeReader);

Vue.config.productionTip = true;
Vue.use(Antd);

// Vue.use(
//   new VueSocketIO({
//     debug: false,
//     //connection: new SocketIO("http://159.89.194.213:1986", {
//     connection: new SocketIO("http://toyotabooth-museumangkut.web.id:6891", {
//       // connection: new SocketIO("http://localhost:3000", {
//       transports: ["websocket"],
//       reconnection: true,
//       reconnectionDelay: 500,
//       maxReconnectionAttempts: Infinity,
//       query: { token: "UNITY" },
//     }),

//     vuex: {
//       store,
//       actionPrefix: "SOCKET_",
//       mutationPrefix: "SOCKET_",
//     },
//     options: {}, //Optional options
//   })
// );

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
