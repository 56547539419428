const WelcomeVIew = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/zoneafterlogin/index.vue"
  );
const HomeWelcome = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/zoneafterlogin/HomeWelcomeView.vue"
  );

const HomeReaderQR = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/zoneafterlogin/ReaderQRView.vue"
  );
const ZoneGuide = () =>
  /* webpackChunkName: "view-[request]" */ import(
    "@/views/zoneafterlogin/ZonaGuideView.vue"
  );

export default [
  {
    path: "/welcome",
    name: "welcome",
    component: WelcomeVIew,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "HomeWelcome",
        component: HomeWelcome,
        meta: { requiresAuth: true },
      },
      {
        path: "/reader-qr",
        name: "HomeReaderQR",
        component: HomeReaderQR,
        meta: { requiresAuth: true },
      },
      {
        path: "/zona-guide",
        name: "ZoneGuide",
        component: ZoneGuide,
        meta: { requiresAuth: true },
      },
    ],
  },
];
